/// <reference path="../../typings/index.d.ts" />

function createSliders() {

	if($( window ).width() <= 1023) {
        $('.manufacture-slider').carouFredSel({
		    circular: true,
			infinite: false,
            responsive: false,
			scroll: { items: 1,
                onBefore: function( data ) {
					if(data.scroll.direction == "next") {
						data.items.old.eq( 0 ).next().removeClass('center');
						data.items.visible.eq( 0 ).next().addClass('center');
		 
					} else {		
						data.items.visible.eq( 0 ).next().addClass('center');
						data.items.old.eq( 0 ).next().removeClass('center');	
					}
				}
			},
			auto: false,
			items: {
				visible: {
					min: 1,
					max: 1
				}
			},
			swipe: {
				onTouch: true
			}
		});
	} else {
		$(".manufacture-slider").trigger("destroy");
	}

    if($( window ).width() < 480) {
        $('.certificates-slider').carouFredSel({
		    circular: true,
			infinite: true,
            responsive: false,
			width: 155,
			scroll: { items: 1,
			},
			auto: false,
			items: {
				visible: {
					min: 1,
					max: 1
				}
			},
			swipe: {
				onTouch: true
			}
		});
    } else {
        $(".certificates-slider").trigger("destroy");
    }

	if($( window ).width() <= 1023) {
        $('.park-slider').carouFredSel({
		    circular: true,
			infinite: false,
            responsive: false,
			scroll: { items: 1,
                onBefore: function( data ) {
					if(data.scroll.direction == "next") {
						data.items.old.eq( 0 ).next().removeClass('center');
						data.items.visible.eq( 0 ).next().addClass('center');
		 
					} else {		
						data.items.visible.eq( 0 ).next().addClass('center');
						data.items.old.eq( 0 ).next().removeClass('center');	
					}
				}
			},
			auto: false,
			items: {
				visible: {
					min: 1,
					max: 1
				}
			},
			swipe: {
				onTouch: true
			}
		});
	} else {
		$(".park-slider").trigger("destroy");
	}

	var clients_slider_height = 505;

	if($( window ).width() < 480) {
		clients_slider_height = 565;

	}
	$('.clients-slider').carouFredSel({
		circular: true,
		infinite: false,
		responsive: false,
		height: clients_slider_height,
		scroll: { items: 1,
            onBefore: function( data ) {
				if(data.scroll.direction == "next") {
                    data.items.old.eq( 0 ).next().removeClass('center');
                    data.items.visible.eq( 0 ).next().addClass('center');
	 
				} else {		
                    data.items.visible.eq( 0 ).next().addClass('center');
                    data.items.old.eq( 0 ).next().removeClass('center');	
				}
			}
		},
		auto: false,
		items: {
			visible: {
				min: 1,
				max: 1
			}
		},
		swipe: {
			onTouch: true
		}
	});

	$('.clients-slider .for_click').click(function(e) {
		e.preventDefault();	
		let index = parseInt($(this).closest('li').attr('number'));
		$('.clients-slider').trigger('slideTo',  index - 1);
	});

	var logos_count = 2;
	if ($( window ).width() >= 480) {
		logos_count = 3;
	}

	$('#logos-slider').carouFredSel({
		circular: true,
		infinite: false,
		responsive: false,
		height: 85,
		scroll: { items: 1
		},
		auto: true,
		items: {
			min: 2,
			max: 5
		},
		swipe: {
			onTouch: true
		}
	});


};

$(function() {
    setTimeout(function () {
		createSliders();
	}, 1000); 


    $(window).resize(function() {        
        createSliders();
    });

});
